import React from 'react'
import SEO from '../components/seo'
import Button from '../components/button'

const IndexPage = () => (
  <>
    <SEO title="Accessibility" />
    <article className="basicPageContainer">
      <h1>Accessibility</h1>
      <p className="quick-text">
        Redfin is committed to maintaining our website's compliance with the Americans
        with Disabilities Act. We strive to provide a website that is accessible to
        everyone and welcome your feedback to improve our website’s accessibility to all
        visitors.
      </p>
      <Button big text="Contact" link="/contact" />
    </article>
  </>
)

export default IndexPage
